import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Modal, Form, Button } from 'react-bootstrap';
import { BiSearch,BiFolderPlus} from 'react-icons/bi';
import { BiShow } from 'react-icons/bi';


const CargarArticulos=({show,handleClose})=> {
 
  return (
<>

<Modal show={show} 
        onHide={handleClose}         
        backdrop="static"
        keyboard={false}>
            
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Ecommerce</Form.Label>
                <Form.Select aria-label="Default select example">
                    <option>Open this select menu</option>
                    <option value="1">Toysmart Colombia</option>
                    <option value="2">Lego Colombia</option>
                    <option value="3">Smartbrands Colombia</option>
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Adjuntar Archivo CSV</Form.Label>
                <Form.Control type="file" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Observacion</Form.Label>
                <Form.Control as="textarea" rows={3} />
            </Form.Group>
        </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cargar Articulos
          </Button>
        </Modal.Footer>
      </Modal>
</>
  );
}
export default CargarArticulos;