import React, { useState, useEffect,useContext } from 'react';
import Swal from 'sweetalert2';
import { Table, Form,Col,Row,Dropdown,FormControl,Button,Badge,Pagination,Container} from 'react-bootstrap';
import {BiMenu,BiSearch,BiArrowBack,BiCategory,BiCloudUpload,BiCloudDownload,BiExport,BiFile} from 'react-icons/bi';
import { useParams,useNavigate,Link } from 'react-router-dom';
import moment from 'moment';
import SpinnerComponent from '../layout/spinner';
import { get, post, put, remove }  from "../../helpers/apiHttp";
import UpdateDetailIntegration from './UpdateDetailIntegration';
import { Datarestapi } from '../../context/Datarestapi';


function ListIntegration() {
  //console.log(useParams().integracion_id);
  const urlcontext = useContext(Datarestapi);
  const {integracion_id}=useParams();
  const {tienda_id}=useParams();
  const {antpage}=useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [integration, setIntegration] = useState([]);
  const [detailintegrations, setDetailintegrations] = useState([]);
  const [store, setStore] = useState([]);
  const [user, setUser] = useState([]);
  const [totaldetail, setTotaldetail] = useState("");
  const [totalerror, setTotalerror] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perpage, setPerpage] = useState(1);
  const [countitem, setCountitem] = useState(1);
  const [paginaActual, setPaginaActual] = useState("1");
  const [lastPage, setLastPage] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState("1");
  const [articuloSeleccionado, setArticuloSeleccionado] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [datal_id, setDatal_id] = useState("");
  const history = useNavigate();
  const token = localStorage.getItem('token');
  const [search, setSearch] = useState(false);

  useEffect(() => {
    obtenerIntegracion();
  }, [currentPage]);

  useEffect(() => {
    const gstionar = JSON.parse(localStorage.getItem('detallefiltro'));
    if (gstionar !== null && gstionar.nombre=="detalleintegracion" && integracion_id==gstionar.id) {
      setSearchText(gstionar.searchText);
      setCurrentPage(gstionar.pagina);
      setSearch(true);
    }else{
      setCurrentPage(1);
    }
  
  }, []);


  async function obtenerIntegracion(){
    setIsLoading(true);
    let $url=`detalleintegracion/search?page=${currentPage}&q=${searchText}&integracion_id=${integracion_id}`;
    const data = await get($url);
    setIsLoading(false);
    updateFiltro(); 
    if (data.result!=null && data.isSuccessful==true && data.result!=null) {
      setIntegration(data.result.integration);
      setDetailintegrations(data.result.detailintegrations.data);
      setStore(data.result.integration.store);
      setUser(data.result.integration.user);
      setTotaldetail(data.result.detailintegrations.total);
      setLastPage(data.result.detailintegrations.last_page);
      setPerpage(data.result.detailintegrations.per_page);
      setTotalerror(data.result.totalerrores);
    }else{
      if (data.statusCode==404) {
          setIntegration("");
          Swal.fire('!! No se encontraron resultados');
      }else{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Erre del sistema',
              footer: ''
            })

      }
    }
  }

   const handleSearch = async (e) =>{
    e.preventDefault();
    let busqueda=e.target.search.value;
    setSearchText(busqueda);
    setIsLoading(true);
    let $url=`detalleintegracion/search?page=${currentPage}&q=${busqueda}&integracion_id=${integracion_id}`;
    const data = await get($url);
      setIsLoading(false);
      updateFiltro(); 
      
       if (data.isSuccessful==true && data.statusCode==200) {
        if (data.result !=null) {
          setDetailintegrations(data.result.detailintegrations.data);
          setLastPage(data.result.detailintegrations.last_page);
          setPerpage(data.result.detailintegrations.per_page);
          renderPaginationButtons();
        }else{
          setDetailintegrations([]);
          setLastPage(1);
          setPerpage(1);
          renderPaginationButtons();
        }
      }
   };
   const handlePageChange = (pageNumber) => {
    let primerItem = 1;
    setCurrentPage(pageNumber);
    primerItem=(pageNumber * perpage) - perpage + 1;
    setCountitem(primerItem);
 
    
  };
  const updateFiltro = () => {
    const detallefiltro = { 
      nombre: 'detalleintegracion', 
      id: integracion_id,
      pagina: currentPage,
      searchText: searchText
    };
    localStorage.setItem('detallefiltro', JSON.stringify(detallefiltro));
  };

  const handleDownloadfile = async() => {
    setIsLoading(true);
    let $url=`integracion/downloadfile/${integracion_id}`;
    const token = localStorage.getItem('token_baken');
    const response = await fetch(urlcontext + $url, {
      method: 'POST',
      accept:"application/json",
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    //const data = await response.json();
    const blob = await response.blob();
    setIsLoading(false);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = integracion_id + 'homologacion_cargada.xlsx';
    document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
};

  const handleEdit = (detail_id) => { 
    if (!isNaN(detail_id) && detail_id !== 0) {
      setArticuloSeleccionado(detail_id);
      setShowModal(true);
    }  
    
  }; 
  const handleDelete = async (integracion_id) => { 
    if (!isNaN(integracion_id) && integracion_id !== 0) {
      Swal.fire({
        title: 'Confirme?',
        text: `Desea eliminar la homologacion con Id ${integracion_id} !`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar!',
        confirmButtonText: 'Si, eliminar!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let $url=`detailintegration/${integracion_id}`;
          const data = await remove($url);
          if (data.statusCode==204 && data.isSuccessful) {
            obtenerIntegracion();
            Swal.fire({
              icon: 'info',
              title: 'Proceso exitoso',
              text: data.errorMessage,
              footer: ''
            });
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Error durante el proceso',
              text: data.errorMessage,
              footer: ''
            });
          }
        }
      })
    }  
    
  }; 
  const handleUpload = async() => {
    setIsLoading(true);
    let $url=`integracion/upload/${integracion_id}`;
    const data = await post($url);
    setIsLoading(false);
    if (data.isSuccessful==true && data.statusCode==200) {
      Swal.fire({
        icon: 'success',
        title: 'Proceso exitoso',
        text: "Las homologaciones se cargaron correctamente",
        footer: ''
      });
      obtenerIntegracion();
    }else if(data.isSuccessful==true && data.statusCode==204){
      Swal.fire({
        icon: 'info',
        title: 'Sin nformacion para procesar',
        text: 'No hay homologaciones pendiente para montar',
        footer: ''
      });
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Error durante el proceso',
        text: data.errorMessage,
        footer: ''
      });
    }
  };

  const handleExport = async() => {
    try{
      setIsLoading(true);
      let $url=`integracion/export`;
      const formData = new FormData();
      formData.append('tienda', store.id);
      formData.append('integration_id', integracion_id);
      const token = localStorage.getItem('token_baken');
          const response = await fetch(urlcontext + $url, {
            method: 'POST',
            body: formData,
            accept:"application/json",
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
      const blob = await response.blob();
      // Crear un objeto URL a partir del blob
      const url = window.URL.createObjectURL(blob);
      // Crear un enlace temporal y simular un clic para descargar el archivo
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'homologacion.xlsx');
      document.body.appendChild(link);
      link.click();
      // Liberar el objeto URL y eliminar el enlace temporal
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      setIsLoading(false);
      //console.log(data);
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error durante el proceso',
        text: error.message,
        footer: ''
      });
    }
  };
  const cerrarModal = () => {
    setArticuloSeleccionado(null);
    setShowModal(false);
  };

  const handleNextPage = () => {
    let primerItem = 1;
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
      primerItem=((currentPage + 1) * perpage) - perpage + 1;
      setCountitem(primerItem);
    }
   
  };

  const handlePreviousPage = () => {
    let primerItem = 1;
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      primerItem=((currentPage - 1) * perpage) - perpage + 1;
      setCountitem(primerItem);
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];

    // Previous button
    buttons.push(
      <Pagination.Item key={"liprevious"} disabled={currentPage == 1} onClick={handlePreviousPage}>
      Previous
    </Pagination.Item>,
    );
    // Page buttons
    for (let i = 1; i <= lastPage; i++) {
      buttons.push(
        <Pagination.Item key={i} active={i == currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>,
      );
    }
    // Next button
    buttons.push(
      <Pagination.Item key={"linext"} disabled={currentPage === lastPage} onClick={handleNextPage}>
      Next
    </Pagination.Item>,
    );
    return buttons;
  };

  const statusColor={
    2:'success',
    1:'warning',
    3:'danger',
  };
return (
<>
{isLoading ? (
        <SpinnerComponent />
      ):(null)}
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"> 
            <h3>Detalle Homologacion SIC - Shopify</h3>
    </div>

    <Container fluid className="row border rounded-1 pt-3">
      <Row>
        <Col><p><strong>Tienda:</strong> {store.nombre}</p></Col>
        <Col><p><strong>Fecha:</strong> {moment(integration.created_at).locale('es').format('DD-MMM-YYYY HH:mm:ss')}</p></Col>
        <Col><p><strong>Usuario:</strong> {user.name}</p></Col>
        <Col><p><strong>Estado:</strong> {integration.status}</p></Col>
      </Row>
      <Row>
      <Col><p><strong>Total homologaciones:</strong> {totaldetail}</p></Col>
      <Col><p><strong>Errores:</strong> {totalerror}</p></Col>
        <Col><Link onClick={()=>handleDownloadfile()}><BiFile size="30px"/>Descargar documento xlsx soporte</Link></Col>
        <Col></Col>
      </Row>
    </Container>



    <Container fluid className="row pt-3 mb-3">
          <div className="col-8">
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            <BiCategory size="24px"/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={()=>handleUpload()} ><BiCloudUpload size="24px"/> Montar homologaciones pendientes</Dropdown.Item>
              <Dropdown.Item onClick={()=>handleExport()}><BiExport size="24px"/> Exportar detalle homologaciones</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </div>
          <div className="col-4">
            <Form className="d-flex" onSubmit={handleSearch} >
              <FormControl type="search" name="search" placeholder="Busca por: id,  estado, observacion" className="me-2" aria-label="Buscar" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
              <Button variant="outline-primary" type="submit"><BiSearch /></Button>
            </Form>
          </div>
      </Container>
    
{/*     <div className="container-fluid mb-3"> */}

<div className="container-fluid">
<div className="row justify-content-center">
    <div className="col-12">
      <div className="table-responsive">
      <Table striped bordered hover className="custom-table table" >
        <thead className="table-header">
          <tr>
            <th>#</th>
            <th>Codigo SIC</th>
            <th>Nombre</th>
            <th>Handle</th>
            <th>Codigo de barra</th>
            <th>Estado</th>
            <th>Errores</th>
            <th>opciones</th>
          </tr>
        </thead>
        <tbody>
       {
        detailintegrations.map((detailintegration, index) => (
            <tr key={detailintegration.id}>
              <td>{countitem + index}</td>
              <td>{detailintegration.sic_id}</td>
              <td>{detailintegration.nombre}</td>
              <td>{detailintegration.handle}</td>
              <td>{detailintegration.ean}</td>
              <td><Badge pill bg={statusColor[detailintegration.status_id]} text="dark">{detailintegration.status.nombre.toUpperCase()}</Badge></td>
              <td>{detailintegration.errores}</td>
              <td>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <BiMenu/>
                </Dropdown.Toggle>
                  <Dropdown.Menu>
                  {detailintegration.status_id=='1' || detailintegration.status_id=='3'?(
                    <>
                    <Dropdown.Item onClick={() => handleEdit(detailintegration.id)}>Editar</Dropdown.Item>
                    <Dropdown.Item onClick={()=>handleDelete(detailintegration.id)}>Cancelar</Dropdown.Item>
                    </>
                  ):(
                    <Dropdown.Item>No hay opciones disponibles</Dropdown.Item>
                  )}
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))
          } 
        </tbody>
      </Table>
      </div>
      </div>
      <div className="col-12 col-md-auto mb-3 flex-wrap overflow-auto justify-content-center">
        <nav aria-label="Page navigation"> 
            <ul className="pagination">
              {renderPaginationButtons()} 
          </ul>
        </nav>
      </div>
      
      </div>
   </div> 
   <UpdateDetailIntegration show={showModal} handleClose={cerrarModal} datail_id={articuloSeleccionado} obtenerIntegracion={obtenerIntegracion}/>
</>
  );
}
export default ListIntegration;