import Swal from 'sweetalert2';
import React, { useState, useEffect,useContext, useRef } from 'react';
import { Form, Button, Container,FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { AuthContext } from '../../context/AuthContext';
import { Datarestapi } from '../../context/Datarestapi';

 function Login({ onLogin }) {
  const urlcontext = useContext(Datarestapi);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [csrfToken, setCsrfToken] = useState('');
  const [recaptchaResponse, setRecaptchaResponse] = useState("");
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const { setIsLoggedIn } = useContext(AuthContext);
  const recaptchaRef = useRef(null);
  const history = useNavigate();

  useEffect(() => {
    setRecaptchaLoaded(true);
  }, []);

  async function handleSubmit(event) {
    try {
        event.preventDefault();
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        formData.append('recaptcha_response', recaptchaResponse);
        // Hacemos la llamada a la API para verificar las credenciales
        if (email.length == 0) {
            Swal.fire('!! El correo no puede ser vacio');
        }else if(password.length == 0){
            Swal.fire('!! El password no puede ser vacio');
        }else{
            const response = await fetch(urlcontext + "login", {
            method: "POST",
            body: formData,
            });
            const data = await response.json();
            //console.log(data);
            if (data.isSuccessful) {
              localStorage.setItem('token_baken', data.result.token);
              localStorage.setItem('usuario_actual', data.result.user.name);
              setIsLoggedIn(true);
              history("/home");
            } else {
              localStorage.removeItem('token_baken');
              setPassword("");
              setEmail("");
              if (recaptchaRef.current) {
                recaptchaRef.current.reset();
              }
              //localStorage.setItem('token', false);

              Swal.fire({
                icon: 'error',
                title: 'Autenticacion incorrecta',
                html: data.errorMessage,
                footer: ''
                })
                history("/");
              }
          }
        } catch (error) {
          // Manejar errores de red u otros errores
          alert("Error de red: " + error.message);
        }
    
  }
  const handleRecaptchaChange = (value) => {
    setRecaptchaResponse(value);
  };  

  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <div className="w-100" style={{ maxWidth: '400px' }}>
      <h2>Login</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="text-start mt-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control name="email" type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </Form.Group>

          <Form.Group className="text-start mt-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control name="password" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
          </Form.Group>
          <Form.Group className="text-start mt-3" controlId="formBasicPassword">
          {recaptchaLoaded && <ReCAPTCHA
            sitekey="6LcHUUUmAAAAAAZGY1lbPX8Zovcyuc2GQXOZQL_l" ref={recaptchaRef}
            onChange={handleRecaptchaChange}
          />}
          </Form.Group>
          <Button className="text-start mt-3" variant="primary" type="submit">
            Login
          </Button>
        </Form>
           
      </div>
    </Container>
  );
};

export default Login;