import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { Table, Form, Button, FormControl,Container,Row } from 'react-bootstrap';
import { BiSearch,BiFolderPlus} from 'react-icons/bi';
import { BiShow } from 'react-icons/bi';
import logo from './logocentral.png';
//import './App.css';

function Home(props) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <img src={logo} alt="Mi Imagen" style={{ maxWidth: '130%', maxHeight: '130%', objectFit: 'contain' }} />
    </div>
  );
}

export default Home;