import React from 'react';
import { NavLink } from 'react-router-dom';
function NavBar(props) {

  return (
        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
             <div className="d-flex flex-column flex-shrink-0 p-3 bg-light">
            <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
            <svg className="bi me-2" width="40" height="32"></svg>
            <span className="fs-4">Menu</span>
            </a>
            <hr/>
            <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
                <NavLink to="/home" className="nav-link" activeclassname="active">
                    <svg className="bi me-2" width="16" height="16"></svg>
                    Home
                </NavLink>
            </li>
         {/*    <li>
                <NavLink to="/gestionararticulos" className="nav-link" activeclassname="active">
                    <svg className="bi me-2" width="16" height="16"></svg>
                    Gestion Articulos
                </NavLink>
            </li> */}
            <li>
                <NavLink to="/gestionardescuentos" className="nav-link" activeclassname="active">
                    <svg className="bi me-2" width="16" height="16"></svg>
                    Gestionar Descuentos
                </NavLink>
            </li>
            <li>
                <NavLink to="/gestionarhomologacion" className="nav-link" activeclassname="active">
                    <svg className="bi me-2" width="16" height="16"></svg>
                    Gestionar Homologaciones
                </NavLink>
            </li>
            <li>
                <NavLink to="/gestionarordenes" className="nav-link" activeclassname="active">
                    <svg className="bi me-2" width="16" height="16"></svg>
                    Gestionar Pedidos
                </NavLink>
            </li>
           {/*  <li>
                <a href="#" className="nav-link link-dark">
                <svg className="bi me-2" width="16" height="16"></svg>
                Gestion de Ventas
                </a>
            </li> */}
            </ul>
            <hr/>
        </div>
    </nav>
  );
}

export default NavBar;