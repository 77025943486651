import { HashRouter } from 'react-router-dom';
import './App.css';
import { AuthProvider } from './context/AuthContext';
import {urlrestapi, Datarestapi } from './context/Datarestapi.jsx';
import AppRouter from './AppRouter';

function App() {
  return (
    <HashRouter basename="/">
    <AuthProvider>
    <Datarestapi.Provider value={urlrestapi.principal}>  
        <AppRouter />
    </Datarestapi.Provider> 
    </AuthProvider>
    </HashRouter>
  );
}
export default App;
