import React, { useState, useEffect, useRef } from 'react';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { Modal, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import SpinnerComponent from '../layout/spinner';
import { useNavigate } from 'react-router-dom';
import { get, put }  from "../../helpers/apiHttp";


const UpdateDetailIntegration = ({ show, handleClose, datail_id,obtenerIntegracion}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [opcionesSelect, setOpcionesSelect] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [integration, setIntegration] = useState("");
  const [detailintegration, setDetailintegration] = useState("");
  const [id, setId] = useState("");
  const [nombre, setNombre] = useState("");
  const [handle, setHandle] = useState("");
  const [sic_id, setSic_id] = useState("");
  const [ean, setEan] = useState("");
  const token = localStorage.getItem('token');
  const history = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('id', id);
    formData.append('nombre', nombre);
    formData.append('handle', handle);
    formData.append('sic_id', sic_id);
    formData.append('ean', ean);
    try {
      if (!isSubmitting) {
        //let $url=`detailintegration/${datail_id}`;
        let $url=`detailintegration/${datail_id}?id=${id}&nombre=${nombre}&handle=${handle}&sic_id=${sic_id}&ean=${ean}`;
        setIsLoading(true);
        const data = await put($url,formData);
        setIsLoading(false);
        if (data.isSuccessful==true && data.statusCode==200) {
          Swal.fire({
            icon: 'success',
            title: 'Proceso exitoso',
            text: "La informacion fue actualizada correctamente",
            footer: '',
            showCancelButton: false,
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) { 
              obtenerIntegracion();
              handleClose();
              //window.location.reload();
            }
          });   
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error durante el proceso',
            text: data.errorMessage,
            footer: ''
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error de formulario',
        text: "Error al enviar el formulario",
        footer: ''
      });
    }
  };

  useEffect(() => {
    if (show) {
      handleDetail ();
    }
  }, [show]);

  const handleDetail = async () =>{
      setIsLoading(true);
        let $url=`detailintegration/${datail_id}/edit`;
        const data = await get($url);
        setIsLoading(false);
        if (data.isSuccessful==true && data.statusCode==200) {
          setIntegration(data.result.integration);
          setDetailintegration(data.result.detailintegration);
            setId(data.result.detailintegration.id);
            setNombre(data.result.detailintegration.nombre);
            setHandle(data.result.detailintegration.handle);
            setSic_id(data.result.detailintegration.sic_id);
            setEan(data.result.detailintegration.ean);
        }else{
            Swal.fire({
              icon: 'info',
              title: 'Sin resultados',
              text: 'La busqueda no errojo resultados',
              footer: ''
            })
        } 
  };
  return (
    <>
    {isLoading ? (
        <SpinnerComponent />
      ) : (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Editar Detalle Homologacion SIC</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" >
                <Form.Label>Id:</Form.Label>
                <Form.Control type="text" disabled  value={id} onChange={(e) => setId(e.target.value)}/>
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>Nombre:</Form.Label>
                <Form.Control type="text"  name='nombre' value={nombre} onChange={(e) => setNombre(e.target.value)} />
              </Form.Group>
              <Form.Group className="text" >
                <Form.Label>Handle Shopify:</Form.Label>
                <Form.Control type="text"  name='handle' value={handle} onChange={(e) => setHandle(e.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>Codigo SIC:</Form.Label>
                <Form.Control type="text" name='sic_id' value={sic_id} onChange={(e) => setSic_id(e.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>Codigo de barra:</Form.Label>
                <Form.Control type="text" name='ean' value={ean} onChange={(e) => setEan(e.target.value)} />
              </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting} onClick={handleSubmit}>
            Actualizar Homologacion
          </Button>
        </Modal.Footer>
      </Modal>
      )}
    </>
  );
}


export default UpdateDetailIntegration;