import React, { useState, useEffect,useContext } from 'react';
import Swal from 'sweetalert2';
import { Table, Form,Col,Row,Dropdown,FormControl,Button,Container,Badge, } from 'react-bootstrap';
import { BiSearch,BiMenu } from 'react-icons/bi';
import SpinnerComponent from '../layout/spinner';
import { Link } from 'react-router-dom';
import { get, remove,construirMensaje, post }  from "../../helpers/apiHttp";
import moment from 'moment';
import { Datarestapi } from '../../context/Datarestapi';
import { useNavigate } from 'react-router-dom';
import RegistrarIdentificacion from './RegistrarIdentificacion';
import RegistrarGuia from './RegistrarGuia';

function GestionarOrdenes(props) {
  const urlcontext = useContext(Datarestapi);
  const [isLoading, setIsLoading] = useState(false);
  const [opcionesSelect, setOpcionesSelect] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [tienda, setTienda] = useState("");
  const [search, setSearch] = useState(false);
  const [results, setResults] = useState([]);
  const token = localStorage.getItem('token');
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState("");
  const [show, setShow] = useState(false);
  const [showguia, setShowguia] = useState(false);
  const handleClose = () => setShow(false);
  const handleShowguia = () => setShowguia(true);
  const handleCloseguia = () => setShowguia(false);
  const history = useNavigate();

  const handleShow = (order) => {
    setShow(true);
    setOrder(order);
  };

  useEffect(() => {
    const gstionar = JSON.parse(localStorage.getItem('gestionar'));
    if (gstionar !== null && gstionar.nombre=="order") {
      setTienda(gstionar.id_tienda);
      setSearchText(gstionar.searchText);
      setSearch(true);
    }
    fetchOpcionesSelect();
  
  },[]);

  useEffect(() => {
    if (search) {
      handleRunSearch();
    }
    setSearch(true);
  },[]); 


  const updateFiltro = () => {
    const gestionar = { 
      nombre: 'order', 
      id_tienda: tienda,
      pagina: 1,
      searchText: searchText
    };
    localStorage.setItem('gestionar', JSON.stringify(gestionar));
  };

  const handleRunSearch = async () =>{
    if (tienda) {
      setOrders([]);
      setIsLoading(true);
        let $url=`backend/consultarOrden?orden_numero=${searchText}&tienda=${tienda}`;
        const data = await get($url);
        setIsLoading(false);
        //if (data.result!=null && data.isSuccessful==true && data.result!=null && data.statusCode==200) {
          if (data.isSuccessful==true && data.statusCode==200) {
          setOrders(data.result);
        }else{
          if (data.statusCode==204) {
            setOrders([]);
            Swal.fire({
              icon: 'warning',
              title: 'Información...',
              text: data.errorMessage,
              footer: ''
            })
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Error...',
                  text: data.errorMessage,
                  footer: ''
                })

          }

        }
    }
  }


  const handleRestartOrder = async (id) =>{
    const formData = new FormData();
    formData.append('tienda', tienda);
    formData.append('idorden', id);
    try {
        let $url='backend/restablecerOrdenbackend';
        setIsLoading(true);
        const data = await post($url,formData);
        setIsLoading(false);
        if (data.isSuccessful==true && data.statusCode==200) {
            construirMensaje('La orden fue restablecida con exito','Proceso completado','success'); 
            handleRunSearch();
        }else{
          construirMensaje(data.errorMessage,'Error durante el proceso','error');
        }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error de formulario',
        text: "Error al enviar el formulario",
        footer: ''
      });
    }
  };

  const handleRestartOrderDescargada = async (id) =>{
    const formData = new FormData();
    formData.append('tienda', tienda);
    formData.append('idorden', id);
    try {
        let $url='backend/restablecerOrdenDecargada';
        setIsLoading(true);
        const data = await post($url,formData);
        setIsLoading(false);
        if (data.isSuccessful==true && data.statusCode==200) {
            construirMensaje('La orden fue restablecida con exito','Proceso completado','success'); 
            handleRunSearch();
        }else{
          construirMensaje(data.errorMessage,'Error durante el proceso','error');
        }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error de formulario',
        text: "Error al enviar el formulario",
        footer: ''
      });
    }
  };

  const handleCargarOrdenBackend = async (id) =>{
    const formData = new FormData();
    formData.append('tienda', tienda);
    formData.append('idorden', id);
    try {
        let $url='backend/cargarOrdenbackend';
        setIsLoading(true);
        const data = await post($url,formData);
        setIsLoading(false);
        if (data.isSuccessful==true && data.statusCode==200) {
            construirMensaje('La orden fue cargada con exito','Proceso completado','success'); 
            handleRunSearch();
        }else{
          construirMensaje(data.errorMessage,'Error durante el proceso','error');
        }
      //construirMensaje(data.errorMessage,'Error durante el proceso','error');
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error de formulario',
        text: "Error al enviar el formulario",
        footer: ''
      });
    }
  };

  const handleNumFacOrder = async (id) =>{
    const formData = new FormData();
    formData.append('tienda', tienda);
    formData.append('idorden', id);
    try {
        let $url='backend/regenararNofactura';
        setIsLoading(true);
        const data = await post($url,formData);
        setIsLoading(false);
        if (data.isSuccessful==true && data.statusCode==200) {
            construirMensaje('Se reasigno correctamente el numero de factura','Proceso completado','success'); 
            handleRunSearch();
        }else{
          construirMensaje(data.errorMessage,'Error durante el proceso','error');
        }
      //construirMensaje(data.errorMessage,'Error durante el proceso','error');
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error de formulario',
        text: "Error al enviar el formulario",
        footer: ''
      });
    }
  };


  const handleSearch = async (e) =>{
    e.preventDefault();
    if (tienda=="") {
      construirMensaje('Seleccione una tienda para poder consultar','Falta parametros','info');
    }else{
      handleRunSearch();
    }
      
   };


const fetchOpcionesSelect = async () => {
    try {
       let $url='tiendas';
        setIsLoading(true);
        const data = await get($url);
        setIsLoading(false); 
        if (data=='401') {
          history("/");
        }else{
          setOpcionesSelect(data.result);
        }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };




  const statusColor={
    0:'success',
    1:'danger',
  };
  //outline-primary
  return (
<>
{isLoading ? (
        <SpinnerComponent />
      ):(null)}
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"> 
            <h2 className="h2">Gestionar Ordenes</h2>
    </div>
   
    <Container fluid className="row pt-3 mb-3">
    <Row>
    <Col>
        
          </Col>
        
          <Col>
              <Form.Group className="mb-3">
                <Form.Select
                  aria-label="Default select example"
                  id="selectOption"
                  name='tienda'
                  value={tienda}
                  onChange={(e) => setTienda(e.target.value)}
                >
                  <option value="">Seleccione una tienda</option>
                  {opcionesSelect.map((opcion) => (
                    <option key={opcion.id} value={opcion.id}>
                      {opcion.nombre}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
            <Form className="d-flex" onSubmit={handleSearch}>
              <FormControl type="search" name="search" placeholder="Busca por: No Orden" className="me-2" aria-label="Buscar" value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
              <Button variant="outline-primary" type="submit"><BiSearch /></Button>
            </Form>
            </Col>
        
            </Row>
        </Container>
    <div className="container-fluid mb-3"> 
    <div className="row">
    <div className="table-responsive "> 
      <Table striped bordered hover className="custom-table table" >
        <thead className='table-header'>
          <tr>
            <th>Id Shpofy</th>
            <th>No Orden</th>
            <th>Tipo de envío</th>
            <th>Valor total</th>
            <th>Contacto</th>
            <th>DNI cliente</th>
            <th>Departamento</th>
            <th>Municipio</th>
            <th>Direccion</th>
            <th>Guia</th>
            <th>Estado Backend</th>
            <th>Msj</th>
            <th>Registro Shopify</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
        {
        orders.map((order, index) => (
            <tr key={order.id}>
              <td>{order.idshopify}</td>
              <td>{order.name}</td>
              <td>{order.tipo_envio}</td>
              <td>$ {order.pago_total}</td>
              <td>{order.contact}</td>
              <td>{order.contactDni}</td>
              <td>{order.departamento}</td>
              <td>{order.municipio}</td>
              <td>{order.direccion}</td>
              <td>{order.guia}</td>
              <td><Badge pill bg={statusColor[order.error]} text="dark">{order.estadoBackend ? order.estadoBackend.toUpperCase() : ''}</Badge></td>
              <td text="dark">{order.msj}</td>
              <td>{moment(order.fechaShopify).locale('es').format('DD-MMM-YYYY HH:mm:ss')}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <BiMenu/>
                  </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item onClick={()=>handleDelete(order.id)}>Cancelar</Dropdown.Item>   */}
                     
                      {order.idestadoBackend==5 && order.error ? (
                          <>
                          <Dropdown.Item key={order.id + 1 } onClick={()=>handleShow(order)}>Registrar Identificación cliente</Dropdown.Item>
                          </>
                        ):(<></>)
                      }
                      {order.idestadoBackend==4 && order.error ? (
                          <>
                          {/* <Dropdown.Item key={order.id + 2 } onClick={()=>handleRestartOrder(order.idshopify)}>Actualizar con guia temporal</Dropdown.Item> */}
                          </>
                        ):(<></>)
                      }
                      {order.idestadoBackend==3 && order.error ? (
                          <>
                           {/* <Dropdown.Item key={order.id + 2 } onClick={()=>handleNumFacOrder(order.idshopify)}>Generar un nuevo numero de factura</Dropdown.Item> */}
                          </>
                        ):(<></>)
                      }
                       {order.idestadoBackend==10 || order.idestadoBackend==11  ? (
                          <>
                          <Dropdown.Item key={order.id + 3 } onClick={()=>handleCargarOrdenBackend(order.idshopify)}>Cargar orden al backend</Dropdown.Item>
                          </>
                        ):(<></>)
                        
                      }
                      {order.idestadoBackend=='1' || order.idestadoBackend=='2' ? (
                          <>
                          <Dropdown.Item key={order.id + 3 } onClick={()=>handleRestartOrder(order.idshopify)}>Confirmar solución</Dropdown.Item>
                          </>
                        ):(<></>)
                        
                      }
                       {order.idestadoBackend==8 ? (
                          <>
                          <Dropdown.Item key={order.id + 2 } onClick={()=>handleRestartOrderDescargada(order.idshopify)}>Restablecer proceso de integracion</Dropdown.Item>
                          </>
                        ):(<></>)
                      }
                      
                      {/* <Dropdown.Item key={order.id + 3 } as={Link} to={`/gestionardescuentos/listardescuentos/${order.id}`}>Ver detalles</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            ))
            }
        </tbody>
      </Table>
      </div>
      </div>
     </div> 
     <RegistrarIdentificacion show={show} handleClose={handleClose} order={order} tienda={tienda} busqueda={handleRunSearch}/>  
      <RegistrarGuia show={showguia} handleClose={handleCloseguia}/> 
</>
  );
}

export default GestionarOrdenes;