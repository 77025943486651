import React, { useState, useEffect, useRef } from 'react';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { Modal, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import SpinnerComponent from '../layout/spinner';
import { useNavigate } from 'react-router-dom';
import { post,get,construirMensaje  }  from "../../helpers/apiHttp";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const RegistrarGuia = ({ show, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [opcionesSelect, setOpcionesSelect] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const token = localStorage.getItem('token');
  const history = useNavigate();
  const [formulario, setFormulario] = useState({
    tienda: "",
    descuentosxlsx: '',
    observacion: '',
    start_date: '',
    end_date: '',
  });

  const resetFormulario = () => {
    setFormulario({
      tipoIdentificacio: "",
      identificacion: ''
    });
    setStartDate(null);
    setEndDate(null);
    setSelectedFile(null);
  };

  //se ejecuta al abrir el formulario
  useEffect(() => {
    if (show) {
      fetchOpcionesSelect();
      resetFormulario();
    }
  }, [show]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormulario((prevFormulario) => ({
      ...prevFormulario,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('archivo', selectedFile);
    formData.append('tienda', formulario.tienda);
    formData.append('observacion', formulario.observacion);
    formData.append('fecha_inicio', moment(startDate).locale('es').format('DD-MM-YYYY'));
    formData.append('fecha_final', moment(endDate).locale('es').format('DD-MM-YYYY'));//archivoxlsx
    try {
      if (!isSubmitting) {
        let $url='descuentos';
        //setIsLoading(true);
        const data = await post($url,formData);
        //setIsLoading(false);
        if (data.isSuccessful==true) {
          if (data.statusCode==210) {
            construirMensaje(data.errorMessage,'Proceso exitoso','info');

          }else{
            construirMensaje("Los descuentos se cargaron correctamente",'Proceso exitoso','success');
            Swal.fire({
              icon: 'success',
              title: 'Proceso exitoso',
              text: "Los descuentos se cargaron correctamente",
              footer: ''
            });
          }
          history(`/gestionardescuentos/listardescuentos/${data.result.id}`);
        }else{
          construirMensaje(data.errorMessage,'Error durante el proceso','error');
        }
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error de formulario',
        text: "Error al enviar el formulario",
        footer: ''
      });
    }
  };
  const fetchOpcionesSelect = async () => {
    try {
       let $url='tiendas';
        setIsLoading(true);
        const data = await get($url);
        setIsLoading(false); 

      setOpcionesSelect(data.result);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  return (
    <>
    {isLoading ? (
        <SpinnerComponent />
      ) : (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Asignar Guia  Temporal </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Form.Group className="mb-3">
              <Form.Label>Tipo Identificacion</Form.Label>
              <Form.Select
                aria-label="Default select example"
                id="selectOption"
                name='tipoIdentificacio'
                value={formulario.tienda}
                onChange={handleInputChange}
              >
                <option value="">Seleccione una opción</option>
                <option key='1' value='CC'>
                    Cédula de Ciudadanía
                </option>
                <option key='2' value='NIT'>
                    NIT
                </option>
                <option key='2' value='CE'>
                    Cédula de Extranjeria
                </option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Identificación</Form.Label>
              <Form.Control
                type="numeric"
                name='identificación'
                value={formulario.observacion}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting} onClick={handleSubmit}>
            Registrar Identificacion
          </Button>
        </Modal.Footer>
      </Modal>
      )}
    </>
  );
}

export default RegistrarGuia;