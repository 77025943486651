import React, { useContext, useEffect } from 'react';
import Login from './components/login/Login';
import GestionarArticulo from './components/articulos/GestionarArticulo';
import ListArticulos from './components/articulos/ListArticulos';
import GestionarDescuentos from './components/descuentos/GestionarDescuentos';
import GestionarOrdenes from './components/ordenes/GestionarOrdenes';
import GestionarPrecios from './components/precios/GestionarPrecios';
import GestionarIntegracion from './components/integracion/GestionarIntegracion';
import ListIntegration from './components/integracion/ListIntegration';
import ListPrecios from './components/precios/ListPrecios';
import NavBar from './components/layout/NavBar';
import Header from './components/layout/Header';
import Breadcrumb from './components/layout/Breadcrumb';
import ListDescuentos from './components/descuentos/ListDescuentos';
import Home from './components/layout/Home';
import { AuthContext } from './context/AuthContext';
import { BrowserRouter, Route, Routes, useParams, Redirect,useNavigate } from 'react-router-dom';
 const tableContainerStyle = {
    maxHeight: '100px', // Altura máxima deseada
    overflowY: 'auto', // Habilite el desbordamiento vertical
  };

function AppRouter() {
  const { isLoggedIn } = useContext(AuthContext);
  const { setIsLoggedIn } = useContext(AuthContext);
  const history = useNavigate();

  useEffect(()=>{
    let token=localStorage.getItem('token_baken');
    //if (token && isLoggedIn) {
      if (token) {
        setIsLoggedIn(true);
    }else{
      setIsLoggedIn(false);
      localStorage.removeItem('token_baken');
      history("/login");
    }
  },[]);

  return (
    <>
{
    isLoggedIn ? (
      <>
    <div className='container-fluid' style={{ '--bs-gutter-x': '0rem' }}>
        <Header/> 
        <NavBar/> 
     <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <Breadcrumb/> 
      <Routes>
            <Route exact path="/home" element={isLoggedIn?(<Home/>):(<Login/>)} ></Route>
            <Route exact path="/gestionararticulos" element={isLoggedIn?(<GestionarArticulo/>):(<Login/>)} ></Route>
            <Route exact path="/gestionararticulos/listarticulos" element={isLoggedIn?(<ListArticulos/>):(<Login/>)} ></Route> 
            <Route exact path="/gestionardescuentos" element={isLoggedIn?(<GestionarDescuentos/>):(<Login/>)} ></Route>
            <Route exact path="/gestionarordenes" element={isLoggedIn?(<GestionarOrdenes/>):(<Login/>)} ></Route> 
            <Route exact path="/gestionarprecios" element={isLoggedIn?(<GestionarPrecios/>):(<Login/>)} ></Route>
            <Route exact path="/gestionarprecios/listarprecios" element={isLoggedIn?(<ListPrecios/>):(<Login/>)} ></Route>
            <Route exact path="/gestionarhomologacion" element={isLoggedIn?(<GestionarIntegracion/>):(<Login/>)} ></Route>
            <Route exact path="/gestionarhomologacion/listarhomologacion/:integracion_id" element={isLoggedIn?(<ListIntegration/>):(<Login/>)} ></Route> 
            <Route exact path="/gestionardescuentos/listardescuentos/:descuento_id" element={isLoggedIn?(<ListDescuentos/>):(<Login/>)} ></Route>    
      </Routes>
    </main> 
     </div> 
     </>
     ):(
        <>
       {/* <Routes>
        <Route exact path="/login" element={<Login/>}></Route>
        </Routes>*/}
         <Login/> 
        </>
     )}
    </>
  );
}

export default AppRouter;