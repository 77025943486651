import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { Table, Form, Button, FormControl } from 'react-bootstrap';
import { BiSearch,BiFolderPlus} from 'react-icons/bi';
import { BiShow } from 'react-icons/bi';
import CargarArticulos from './CargarArticulos';


function GestionarArticulo(props) {
  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState([]);
  const token = localStorage.getItem('token');
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    // Definimos una función para hacer la llamada a la API y actualizar los resultados
    async function fetchData() {
      const response = await fetch(`https://localhost:7114/api/Car_cliente/nit?id=${searchText}`,{
        headers: {
            'Authorization': `Bearer ${token}`,
          }
      });
      const data = await response.json();
      if (data.isExitoso==true && data.resultado!=null) {
        setResults(data.resultado);
      }else{
        if (data.statusCode==404) {
            setResults("");
            Swal.fire('!! No se encontraron resultados');
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Erre del sistema',
                footer: ''
              })

        }
        setSearchText("");
      }
      
    }

    // Verificamos si hay texto de búsqueda y llamamos a la función fetchData
    if (searchText !== "") {
      fetchData();
    }
  }, [searchText]);

  return (
<>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"> 
          <h2 className="h2">Gestionar Articulo</h2>
    </div>
    <div className="container-fluid">
    <div className="row">
        <div className="col-8">
        <Button variant="outline-primary" onClick={handleShow}><BiFolderPlus size="20px"/> Cargar Articulos</Button>{' '}
        </div>
          <div className="col-4">
            <Form className="d-flex">
              <FormControl type="search" placeholder="Buscar" className="me-2" aria-label="Buscar" />
              <Button variant="outline-primary" type="submit"><BiSearch /></Button>
            </Form>
          </div>
    </div>
    </div>
      <Table responsive striped bordered hover className="table mt-5">
        <thead className='table-header'>
          <tr>
            <th>Id</th>
            <th>Usuario</th>
            <th>Tienda</th>
            <th>Fecha de proceso</th>
            <th>CSV</th>
            <th># de articulos creados</th>
            <th># articulos con errores</th>
            <th>Observacion</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>5</td>
              <td>
                  <Link to="/gestionararticulos/listarticulos">
                    <svg className="bi me-2" width="16" height="16"></svg>
                    Ver Articulos
                  </Link>
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>5</td>
              <td>
                  <Link to="/gestionararticulos/listarticulos">
                    <svg className="bi me-2" width="16" height="16"></svg>
                    Ver Articulos
                  </Link>
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>5</td>
              <td>
                  <Link to="/gestionararticulos/listarticulos">
                    <svg className="bi me-2" width="16" height="16"></svg>
                    Ver Articulos
                  </Link>
              </td>
            </tr>
        </tbody>
      </Table>
      <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item"><a className="page-link" href="#">Previous</a></li>
            <li className="page-item"><a className="page-link" href="#">1</a></li>
            <li className="page-item"><a className="page-link" href="#">2</a></li>
            <li className="page-item"><a className="page-link" href="#">3</a></li>
            <li className="page-item"><a className="page-link" href="#">Next</a></li>
          </ul>
        </nav>
      <CargarArticulos show={show} handleClose={handleClose}/>
</>
  );
}

export default GestionarArticulo;