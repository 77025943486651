import React, { useState, useEffect,useContext } from 'react';
import Swal from 'sweetalert2';
import { Table, Form,Col,Row,Dropdown,FormControl,Button,Badge,Pagination,Container} from 'react-bootstrap';
import {BiMenu,BiSearch,BiCloudUpload,BiCloudDownload,BiExport,BiCategory,BiFile} from 'react-icons/bi';
import { useParams,useNavigate,Link} from 'react-router-dom';
import moment from 'moment';
import SpinnerComponent from '../layout/spinner';
import { get, post, put, remove,construirMensaje }  from "../../helpers/apiHttp";
import { Datarestapi } from '../../context/Datarestapi';


function ListDescuentos() {
  const urlcontext = useContext(Datarestapi);
  const {descuento_id}=useParams();
  const {tienda_id}=useParams();
  const {antpage}=useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [discount, setDiscount] = useState([]);
  const [detaildiscounts, setDetaildiscounts] = useState([]);
  const [store, setStore] = useState([]);
  const [user, setUser] = useState([]);
  const [totaldetail, setTotaldetail] = useState("");
  const [totalerror, setTotalerror] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perpage, setPerpage] = useState(1);
  const [countitem, setCountitem] = useState(1);
  const [paginaActual, setPaginaActual] = useState("1");
  const [lastPage, setLastPage] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState("1");
  const [articuloSeleccionado, setArticuloSeleccionado] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [datal_id, setDatal_id] = useState("");
  const history = useNavigate();
  const token = localStorage.getItem('token');
  const options = {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  useEffect(() => {
    obtenerDescuentos();
  }, [currentPage]);

  async function obtenerDescuentos(){
    setIsLoading(true);
    let $url=`detalledescuento/search?page=${currentPage}&q=${searchText}&discount_id=${descuento_id}`;
    const data = await get($url);
    setIsLoading(false);
    if (data.statusCode==200) {
      setDiscount(data.result.discount);
      setDetaildiscounts(data.result.detaildiscounts.data);
      setStore(data.result.discount.store);
      setUser(data.result.discount.user);
      setTotaldetail(data.result.detaildiscounts.total);
      setLastPage(data.result.detaildiscounts.last_page);
      setPerpage(data.result.detaildiscounts.per_page);
      setTotalerror(data.result.totalerrores);
    }else{
      if (data.statusCode==404) {
          setDiscount("");
          construirMensaje('!! No se encontraron resultados','Proceso exitoso','info');
      }else{
        construirMensaje('Erre del sistema','Oops...','error');

      }
    }
  }

   const handleSearch = async (e) =>{
    e.preventDefault();
    setIsLoading(true);
    let $url=`detalledescuento/search?q=${searchText}&discount_id=${descuento_id}`;
    const data = await get($url);
      setIsLoading(false);
      if (data.isSuccessful==true && data.statusCode==200) {
        if (data.result !=null) {
          setDetaildiscounts(data.result.detaildiscounts.data);
          setLastPage(data.result.detaildiscounts.last_page);
          setPerpage(data.result.detaildiscounts.per_page);
          renderPaginationButtons();
        }else{
          setDetaildiscounts([]);
          setLastPage(1);
          setPerpage(1);
          renderPaginationButtons();
        }
      }
   };

   const handlePageChange = (pageNumber) => {
    let primerItem = 1;
    setCurrentPage(pageNumber);
    primerItem=(pageNumber * perpage) - perpage + 1;
    setCountitem(primerItem);
  };

  const handleEdit = (detail_id) => { 
    if (!isNaN(detail_id) && detail_id !== 0) {
      setArticuloSeleccionado(detail_id);
      setShowModal(true);
    }  
  }; 

  const handleDelete = async (detail_id) => { 
    if (!isNaN(detail_id) && detail_id !== 0) {
      Swal.fire({
        title: 'Confirme?',
        text: `Desea eliminar el descuento con Id ${detail_id} !`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar!',
        confirmButtonText: 'Si, eliminar!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let $url=`detalledescuento/${detail_id}`;
          const data = await remove($url);
          if (data.statusCode==204 && data.isSuccessful) {
            obtenerDescuentos();
            construirMensaje(data.errorMessage,'Proceso exitoso','success');
          }else{
            construirMensaje(data.errorMessage,'Error durante el proceso','error');
          }
        }
      })
    }  
    
  }; 

  const discountDownload = async (detail_id) => { 
    if (!isNaN(detail_id) && detail_id !== 0) {
      Swal.fire({
        title: 'Confirme?',
        text: `Desea desmontar el descuento con Id ${detail_id} !`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar!',
        confirmButtonText: 'Si, desmontar!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          let $url=`detalledescuento/desactivar/${detail_id}`;
          const data = await post($url);
          setIsLoading(false);
          if (data.statusCode==200 && data.isSuccessful) {
            obtenerDescuentos();
            construirMensaje(data.errorMessage,'Proceso exitoso','success');
          }else{
            construirMensaje(data.errorMessage,'Error durante el proceso','error');
          }
        }
      })
    }  
    
  };

  const handleUpload = async() => {
    setIsLoading(true);
    let $url=`descuentos/activate/${descuento_id}`;
    const data = await post($url);
    setIsLoading(false);
    if (data.isSuccessful==true && data.statusCode==200) {
      Swal.fire({
        icon: 'success',
        title: 'Proceso exitoso',
        text: "Los descuentos se montaron correctamente",
        footer: '',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }else if(data.isSuccessful==true && data.statusCode==210){
      Swal.fire({
        icon: 'success',
        title: 'Proceso en segundo plano',
        text: data.result,
        footer: '',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }else{
      construirMensaje(data.errorMessage,'Error durante el proceso','error');
    }
  };
  const handleDownload = async() => {
      setIsLoading(true);
      let $url=`descuentos/desactivar/${descuento_id}`;
      const data = await post($url);
      setIsLoading(false);
      if (data.isSuccessful==true && data.statusCode==200) {
        Swal.fire({
          icon: 'success',
          title: 'Proceso exitoso',
          text: "EL proceso termino correctamente",
          footer: '',
          showCancelButton: false,
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }else if(data.isSuccessful==true && data.statusCode==210){
        Swal.fire({
          icon: 'info',
          title: 'Proceso en segundo plano',
          text: data.errorMessage,
          footer: '',
          showCancelButton: false,
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
      }else{
        //construirMensaje(data.errorMessage,'Error durante el proceso','error');
      }
  };

  const handleExport = async() => {
    setIsLoading(true);
    try {
      let $url=`descuentos/export`;
      const formData = new FormData();
      formData.append('tienda', store.id);
      formData.append('discount_id', descuento_id);
      const token = localStorage.getItem('token_baken');
          const response = await fetch(urlcontext + $url, {
            method: 'POST',
            body: formData,
            accept:"application/json",
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
      const blob = await response.blob();
      // Crear un objeto URL a partir del blob
      const url = window.URL.createObjectURL(blob);
      // Crear un enlace temporal y simular un clic para descargar el archivo
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'discount.xlsx');
      document.body.appendChild(link);
      link.click();
      // Liberar el objeto URL y eliminar el enlace temporal
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      setIsLoading(false);
    //console.log(data);
  } catch (error) {
    setIsLoading(false);
    construirMensaje(error.message,'Error durante el proceso','error');
  }
};

const handleDownloadfile = async() => {
  try{
    setIsLoading(true);
    let $url=`descuento/downloadfile/${descuento_id}`;
    console.log();
    const token = localStorage.getItem('token_baken');
    const response = await fetch(urlcontext + $url, {
      method: 'POST',
      body: "",
      accept:"application/json",
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    //const data = await response.json();
    const blob = await response.blob();
    setIsLoading(false);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = descuento_id + 'archivo.xlsx';
    document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      setIsLoading(false);
      construirMensaje(error.message,'Error durante el proceso','error');
    }
};


  const cerrarModal = () => {
    setArticuloSeleccionado(null);
    setShowModal(false);
  };
  const handleNextPage = () => {
    let primerItem = 1;
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
      primerItem=((currentPage + 1) * perpage) - perpage + 1;
      setCountitem(primerItem);
    }
   
  };
  const handlePreviousPage = () => {
    let primerItem = 1;
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      primerItem=((currentPage - 1) * perpage) - perpage + 1;
      setCountitem(primerItem);
    }
  };
  const renderPaginationButtons = () => {
    const buttons = [];

    // Previous button
    buttons.push(
      <Pagination.Item key={"liprevious"} disabled={currentPage === 1} onClick={handlePreviousPage}>
      Previous
    </Pagination.Item>,
    );
    // Page buttons
    for (let i = 1; i <= lastPage; i++) {
      buttons.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>,
      );
    }
    // Next button
    buttons.push(
      <Pagination.Item key={"linext"} disabled={currentPage === lastPage} onClick={handleNextPage}>
      Next
    </Pagination.Item>,
    );
    return buttons;
  };

  const statusColor={
    2:'success',
    1:'warning',
    3:'secondary',
    4:'danger',
  };
return (
<>
{isLoading ? (
        <SpinnerComponent />
      ):(null)}
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"> 
            <h3>Detalles Descuento</h3>
    </div>
    <Container fluid className="row border rounded-1 pt-3">
      <Row>
        <Col><p><strong>Tienda:</strong> {store.nombre}</p></Col>
        <Col><p><strong>Fecha:</strong> {moment(discount.created_at).locale('es').format('DD-MMM-YYYY HH:mm:ss')}</p></Col>
        <Col><p><strong>Usuario:</strong> {user.name}</p></Col>
        <Col><p><strong>Total descuentos:</strong> {totaldetail}</p></Col>
        <Col><p><strong>Errores:</strong> {totalerror}</p></Col>
      </Row>
      <Row>
        <Col><p><strong>Fecha inicio:</strong> {moment(discount.start_date).locale('es').format('DD-MMM-YYYY')}</p></Col>
        <Col><p><strong>Fecha de expiracion:</strong> {moment(discount.end_date).locale('es').format('DD-MMM-YYYY')}</p></Col>
        <Col><Link onClick={()=>handleDownloadfile()}><BiFile size="30px"/>Descargar documento xlsx soporte</Link></Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </Container>

    <Container fluid className="row pt-3 mb-3">
          <div className="col-8">
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            <BiCategory size="24px"/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={()=>handleUpload()}><BiCloudUpload size="24px"/> Montar descuento en Shopify</Dropdown.Item>
              <Dropdown.Item onClick={()=>handleDownload()}><BiCloudDownload size="24px"/> Desmontar lote descuentos en Shopify</Dropdown.Item>
              <Dropdown.Item onClick={()=>handleExport()} ><BiExport size="24px"/> Exportar desceuntos en Excel</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </div>
            <div className="col-4">
              <Form className="d-flex" onSubmit={handleSearch} >
                <FormControl type="search" name="search" placeholder="Busca por: id,  estado, observacion" className="me-2" aria-label="Buscar" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                <Button variant="outline-primary" type="submit"><BiSearch /></Button>
              </Form>
            </div>
      </Container>
<div className="container-fluid">
<div className="row justify-content-center">
    <div className="col-12">
      <div className="table-responsive">
      <Table striped bordered hover className="custom-table table" >
      <thead className="table-header">
          <tr>
            <th>#</th>
            <th>Id</th>
            <th>Codigo de barra</th>
            <th>Precio normal</th>
            <th>Precio en descuento</th>
            <th>Porcentaje</th>
            <th>Estado</th>
            <th>Errores</th>
            <th>opciones</th>
          </tr>
        </thead>
        <tbody>
       {
        detaildiscounts.map((detaildiscount, index) => (
            <tr key={detaildiscount.id}>
              <td>{countitem + index}</td>
              <td>{detaildiscount.id}</td>
              <td>{detaildiscount.ean}</td>
              <td>{detaildiscount.normalprice?(detaildiscount.normalprice.toLocaleString('es-CO', options)):("")}</td>
              <td>{detaildiscount.saleprice?(detaildiscount.saleprice.toLocaleString('es-CO', options)):("")}</td>
              <td>{Number(((detaildiscount.normalprice - detaildiscount.saleprice)/detaildiscount.normalprice) * 100 ).toFixed(2)} %</td>
              <td><Badge pill bg={statusColor[detaildiscount.status_id]} text="dark">{detaildiscount.status.nombre.toUpperCase()}</Badge></td>
              <td>{detaildiscount.errores}</td>
              <td>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <BiMenu/>
                </Dropdown.Toggle>
                  <Dropdown.Menu>
                  {detaildiscount.status_id=='1' || detaildiscount.status_id=='4'?(
                    <>
                    <Dropdown.Item onClick={()=>handleDelete(detaildiscount.id)}>Cancelar</Dropdown.Item>
                    </>
                  ):(
                    <Dropdown.Item as={Link} to={store.urlclientes + detaildiscount.urlvariant} target="_blank">Ver en Tienda</Dropdown.Item>
                  )}
                  {detaildiscount.status_id=='2'?(
                    <>
                    <Dropdown.Item onClick={()=>discountDownload(detaildiscount.id)}>Desactivar descuento</Dropdown.Item>
                    </>
                  ):(<></>)}
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))
          } 
        </tbody>
      </Table>
      </div>
      </div>
      <div className="col-12 col-md-auto mb-3 flex-wrap overflow-auto justify-content-center">
        <nav aria-label="Page navigation"> 
            <ul className="pagination">
              {renderPaginationButtons()} 
          </ul>
        </nav>
      </div>
      
      </div>
   </div> 
</>
  );
}
export default ListDescuentos;