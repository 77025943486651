import React, { useState, useEffect, useRef } from 'react';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { Modal, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import SpinnerComponent from '../layout/spinner';
import { useNavigate } from 'react-router-dom';
import { get, post, put, construirMensaje }  from "../../helpers/apiHttp";


const UpdatePassword = ({ show, handleClose}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = localStorage.getItem('token');
  const history = useNavigate();

  const [formulario, setFormulario] = useState({
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormulario((prevFormulario) => ({
      ...prevFormulario,
      [name]: value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
     formData.append('current_password', formulario.current_password);
     formData.append('new_password', formulario.new_password);
     formData.append('new_password_confirmation', formulario.new_password_confirmation);
    try {
      if (!isSubmitting) {
        let $url=`user/changepassword`;
        setIsLoading(true);
        const data = await post($url,formData);
        setIsLoading(false);
        if (data.isSuccessful==true && data.statusCode==200) {
            construirMensaje(data.result,'Proceso exitoso','success');
            handleClose();  
        }else{
            construirMensaje(data.errorMessage,'Error de formulario','error');
        }
      }
    } catch (error) {
        construirMensaje(error.message,'Error de formulario','error');
    } 
  };
  useEffect(() => {
    if (show) {
        setFormulario({
            current_password: "",
            new_password: "",
            new_password_confirmation: "",
          });
    }
  }, [show]);

  return (
    <>
    {isLoading ? (
        <SpinnerComponent />
      ) : (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Actualizar Contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <Form.Group className="mb-3" >
                <Form.Label>Contraseña actual:</Form.Label>
                <Form.Control name="current_password" type="password" placeholder="Actual contraseña" value={formulario.current_password} onChange={handleInputChange} required/>
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>Nueva contraseña:</Form.Label>
                <Form.Control name="new_password" type="password" placeholder="Nueva contraseña" value={formulario.new_password} onChange={handleInputChange} required/>
              </Form.Group>
              <Form.Group className="text" >
                <Form.Label>Repita nueva contraseña:</Form.Label>
                <Form.Control name="new_password_confirmation" type="password" placeholder="Confirme contraseña" value={formulario.new_password_confirmation} onChange={handleInputChange} required/>
              </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting} onClick={handleSubmit}>
            Actualizar Contraseña
          </Button>
        </Modal.Footer>
      </Modal>
      )}
    </>
  );
}
export default UpdatePassword;