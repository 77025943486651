import React, { useState, useEffect,useContext } from 'react';
import Swal from 'sweetalert2';
import { Table, Form,Col,Row,Dropdown,FormControl,Button,Badge,Pagination,Container } from 'react-bootstrap';
import { BiSearch,BiShow,BiFolderPlus,BiMenu,BiImport,BiExport,BiCategory } from 'react-icons/bi';
import SpinnerComponent from '../layout/spinner';
import { Link,useHistory,useParams } from 'react-router-dom';
import CargarDescuentos from './CargarDescuentos';
import { get, post, put, remove,construirMensaje }  from "../../helpers/apiHttp";
import moment from 'moment';
import { Datarestapi } from '../../context/Datarestapi';
import { useNavigate } from 'react-router-dom';

function GestionarDescuentos(props) {
  const urlcontext = useContext(Datarestapi);
  const [isLoading, setIsLoading] = useState(false);
  const [opcionesSelect, setOpcionesSelect] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [tienda, setTienda] = useState("");
  const [search, setSearch] = useState(false);
  const [searchaux, setSearchaux] = useState("");
  const [results, setResults] = useState([]);
  const token = localStorage.getItem('token');
  const [discounts, setDiscounts] = useState([]);
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [perpage, setPerpage] = useState(1);
  const [countitem, setCountitem] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const history = useNavigate();

  useEffect(() => {
    const gstionar = JSON.parse(localStorage.getItem('gestionar'));
    if (gstionar !== null && gstionar.nombre=="descuento") {
      setTienda(gstionar.id_tienda);
      setSearchText(gstionar.searchText);
      setCurrentPage(gstionar.pagina);
      setSearch(true);
    }else{
      setCurrentPage(1);
    }
    fetchOpcionesSelect();
  
  }, []);

  useEffect(() => {
    if (search) {
      handleRunSearch();
    }
    setSearch(true);
  }, [currentPage]); 


  const updateFiltro = () => {
    const gestionar = { 
      nombre: 'descuento', 
      id_tienda: tienda,
      pagina: currentPage,
      searchText: searchText
    };
    localStorage.setItem('gestionar', JSON.stringify(gestionar));
  };

  const handleRunSearch = async () =>{
    if (tienda) {
      setIsLoading(true);
        let $url=`descuentos?q=${searchText}&page=${currentPage}&tienda=${tienda}`;
        console.log($url);
        const data = await get($url);
        setIsLoading(false);
        updateFiltro();
        if (data.result!=null && data.isSuccessful==true && data.result.data!=null) {
          setDiscounts(data.result.data);
          setLastPage(data.result.last_page);
          setPerpage(data.result.per_page);
          renderPaginationButtons();
        }else{
          if (data.statusCode==204) {
            setDiscounts([]);
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Erre del sistema',
                  footer: ''
                })

          }

        }
    }
  }
  const handleSearch = async (e) =>{
    e.preventDefault();
    if (tienda=="") {
      construirMensaje('Seleccione una tienda para poder consultar','Falta parametros','info');
    }else{
      setCurrentPage(1);
      handleRunSearch();
    }
      
   };

   const handleDelete = async (descuento_id) => { 
    if (!isNaN(descuento_id) && descuento_id !== 0) {
      Swal.fire({
        title: 'Confirme?',
        text: `Desea eliminar el lote de descuentos con Id ${descuento_id} !`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let $url=`descuentos/${descuento_id}`;
          const data = await remove($url);
          if (data.statusCode==204 && data.isSuccessful) {
            handleRunSearch();
            Swal.fire({
              icon: 'success',
              title: 'Proceso exitoso',
              text: 'El lote de descuento fue eliminado con exito',
              footer: ''
            });
          }else{
            construirMensaje(data.errorMessage,'Error durante el proceso','error');
          }
        }
      })
    }  
    
  }; 
const fetchOpcionesSelect = async () => {
    try {
       let $url='tiendas';
        setIsLoading(true);
        const data = await get($url);
        setIsLoading(false); 
        if (data=='401') {
          history("/");
        }else{
          setOpcionesSelect(data.result);
        }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleExport = async() => {
    try{
        if (tienda=="") {
          construirMensaje('Seleccione una tienda para poder consultar','Falta parametros','info');
        }else{
            setIsLoading(true);
            let $url=`descuentos/export`;
            const formData = new FormData();
            formData.append('tienda', tienda);
            const token = localStorage.getItem('token_baken');
                const response = await fetch(urlcontext + $url, {
                  method: 'POST',
                  body: formData,
                  accept:"application/json",
                  headers: {
                    'Authorization': `Bearer ${token}`,
                  },
                });
                //const data = await response.json();
                const blob = await response.blob();
                // Crear un objeto URL a partir del blob
                const url = window.URL.createObjectURL(blob);
                // Crear un enlace temporal y simular un clic para descargar el archivo
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'discount.xlsx');
                document.body.appendChild(link);
                link.click();
                // Liberar el objeto URL y eliminar el enlace temporal
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
                setIsLoading(false);
                //console.log(data);
        }
      } catch (error) {
        setIsLoading(false);
        construirMensaje(error.message,'Error durante el proceso','error');
      }
};

const handleDownloadTemplete = async() => {
  try{
      setIsLoading(true);
      let $url=`descuentos/templete`;
      const formData = new FormData();
      formData.append('tienda', tienda);
      const token = localStorage.getItem('token_baken');
          const response = await fetch(urlcontext + $url, {
            accept:"application/json",
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          const blob = await response.blob();
          // Crear un objeto URL a partir del blob
          const url = window.URL.createObjectURL(blob);
          // Crear un enlace temporal y simular un clic para descargar el archivo
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'descuentoTemplete.xlsx');
          document.body.appendChild(link);
          link.click();
          // Liberar el objeto URL y eliminar el enlace temporal
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          construirMensaje(error.message,'Error durante el proceso','error');
        }

};
const handlePageChange = (pageNumber) => {
    let primerItem = 1;
    setCurrentPage(pageNumber);
    primerItem=(pageNumber * perpage) - perpage + 1;
    setCountitem(primerItem);
    //const nuevaURL = `/gestionardescuentos/${pageNumber}`;
    //history.replace(nuevaURL);
  };
  const handleNextPage = () => {
    let primerItem = 1;
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
      primerItem=((currentPage + 1) * perpage) - perpage + 1;
      setCountitem(primerItem);
    }
   
  };

  const handlePreviousPage = () => {
    let primerItem = 1;
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      primerItem=((currentPage - 1) * perpage) - perpage + 1;
      setCountitem(primerItem);
    }
  };
  const renderPaginationButtons = () => {
    const buttons = [];
    // Previous button
    buttons.push(
      <li className="page-item" key='liprevious'>
        <button key="previous" className="page-link" disabled={currentPage === 1} onClick={handlePreviousPage}>
          Previous
        </button>
      </li>
    );

    // Page buttons
    for (let i = 1; i <= lastPage; i++) {
      buttons.push(
        <li className="page-item" key={'li-'+ i}>
        <button key={i} className={`page-link ${currentPage == i ? 'active' : ''}`} onClick={() => handlePageChange(i)}>
          {i}
        </button>
        </li>
      );
    }

    // Next button
    buttons.push(
      <li className="page-item" key='linext'>
      <button
        key="next"
        className="page-link"
        disabled={currentPage === lastPage}
        onClick={handleNextPage}
      >
        Next
      </button>
      </li>
    );

    return buttons;
  };
  const statusColor={
    ok:'success',
    pending:'warning',
    error:'danger',
  };
  //outline-primary
  return (
<>
{isLoading ? (
        <SpinnerComponent />
      ):(null)}
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"> 
            <h2 className="h2">Gestionar Descuentos</h2>
    </div>
   
    <Container fluid className="row pt-3 mb-3">
    <Row>
    <Col>
        <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            <BiCategory size="24px"/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={()=>handleShow()}><BiImport size="24px"/> Importar descuentos</Dropdown.Item>
              <Dropdown.Item onClick={()=>handleExport()}><BiExport size="24px"/> Exportar descuentos Activos y Pendientes</Dropdown.Item>
              <Dropdown.Item onClick={()=>handleDownloadTemplete()}><BiExport size="24px"/> Descargar plantilla xlsx para montar decuentos </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </Col>
        
          <Col>
              <Form.Group className="mb-3">
                {/* <Form.Label>Tienda</Form.Label> */}
                <Form.Select
                  aria-label="Default select example"
                  id="selectOption"
                  name='tienda'
                  value={tienda}
                  onChange={(e) => setTienda(e.target.value)}
                >
                  <option value="">Seleccione una tienda</option>
                  {opcionesSelect.map((opcion) => (
                    <option key={opcion.id} value={opcion.id}>
                      {opcion.nombre}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
            <Form className="d-flex" onSubmit={handleSearch}>
              <FormControl type="search" name="search" placeholder="Busca por: id, usuario, estado, observacion" className="me-2" aria-label="Buscar" value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
              <Button variant="outline-primary" type="submit"><BiSearch /></Button>
            </Form>
            </Col>
        
            </Row>
        </Container>
    <div className="container-fluid mb-3"> 
    <div className="row">
    <div className="table-responsive "> 
      <Table striped bordered hover className="custom-table table" >
        <thead className='table-header'>
          <tr>
            <th>#</th>
            <th>Id</th>
            <th>Tienda</th>
            <th>Usuario</th>
            <th>Fecha proceso</th>
            {/* <th>Estado</th> */}
            <th>Fecha inicio</th>
            <th>Fecha final</th>
            <th>Observacion</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
        {
        discounts.map((discount, index) => (
            <tr key={discount.id}>
              <td>
                {countitem + index}
              </td>
              <td>{discount.id}</td>
              <td>{discount.store.nombre}</td>
              <td>{discount.user ? discount.user.name : 'Usuario '}</td>
             {/* <td>{discount.user.name}</td>*/}
              <td>{moment(discount.created_at).locale('es').format('DD-MMM-YYYY HH:mm:ss')}</td>
              <td>{moment(discount.start_date).locale('es').format('DD-MMM-YYYY')}</td>
              <td>{moment(discount.end_date).locale('es').format('DD-MMM-YYYY')}</td>
              <td>{discount.observation}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <BiMenu/>
                  </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <Dropdown.Item as={Link} to={`/gestionardescuentos/listardescuentos/${discount.id}`}>Ver detalles</Dropdown.Item>
                      <Dropdown.Item onClick={()=>handleDelete(discount.id)}>Cancelar</Dropdown.Item>  
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            ))
            }
        </tbody>
      </Table>
      </div>
        <div className="d-flex justify-content-center">
        <nav aria-label="Page navigation example"> 
          <ul className="pagination">
            {renderPaginationButtons()}
          </ul>
        </nav>
      </div>
      </div>
     </div>
      <CargarDescuentos show={show} handleClose={handleClose}/>     
</>
  );
}

export default GestionarDescuentos;