import React, { useState, useEffect,useContext } from 'react';
import Swal from 'sweetalert2';
import { Table, Form,Col,Row,Dropdown,FormControl,Button,Badge,Pagination,Container} from 'react-bootstrap';
import { BiSearch,BiShow,BiFolderPlus,BiMenu,BiExport,BiCategory} from 'react-icons/bi';
import SpinnerComponent from '../layout/spinner';
import { Link,useParams } from 'react-router-dom';
import RegistrarIntegracion from './RegistrarIntegracion';
import { get, post, put, remove,construirMensaje }  from "../../helpers/apiHttp";
import moment from 'moment';
import { Datarestapi } from '../../context/Datarestapi';
import { useNavigate } from 'react-router-dom';

function GestionarIntegracion(props) {
  const urlcontext = useContext(Datarestapi);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [opcionesSelect, setOpcionesSelect] = useState([]);
  const [results, setResults] = useState([]);
  const token = localStorage.getItem('token');
  const [tienda, setTienda] = useState("");
  const [search, setSearch] = useState(false);
  const [integrations, setIntegrations] = useState([]);
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [perpage, setPerpage] = useState(1);
  const [countitem, setCountitem] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const history = useNavigate();

  useEffect(() => {
    const gstionar = JSON.parse(localStorage.getItem('gestionar'));
    if (gstionar !== null && gstionar.nombre=="integracion") {
      setTienda(gstionar.id_tienda);
      setSearchText(gstionar.searchText);
      setCurrentPage(gstionar.pagina);
      setSearch(true);
    }else{
      setCurrentPage(1);
    }
    fetchOpcionesSelect();
  }, []);

  useEffect(() => {
    if (search) {
      handleRunSearch();
    }
    setSearch(true);
  }, [currentPage]); 

  const handleRunSearch = async (e) =>{
    if (tienda) {
      setIsLoading(true);
        let $url=`integracion?q=${searchText}&page=${currentPage}&tienda=${tienda}`;
        const data = await get($url);
        setIsLoading(false);
        updateFiltro();
        if (data.result!=null && data.isSuccessful==true && data.result.data!=null) {
          setIntegrations(data.result.data);
          setLastPage(data.result.last_page);
          setPerpage(data.result.per_page);
          renderPaginationButtons();
        }else{
          if (data.statusCode==204) {
            setIntegrations([]);
          }else{
            construirMensaje('Erre del sistema','Oops...','error');
          }

        }
      }
  };

  const handleSearch = async (e) =>{
    e.preventDefault();
    if (tienda=="") {
      construirMensaje('Seleccione una tienda para poder consultar','Falta parametros','info');
    }else{
      setCurrentPage(1);
      handleRunSearch();
    } 
      
   };
   const fetchOpcionesSelect = async () => {
    try {
       let $url='tiendas';
        setIsLoading(true);
        const data = await get($url);
        setIsLoading(false); 
        if (data=='401') {
          history("/");
        }else{
          setOpcionesSelect(data.result);
        }
        
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };


const updateFiltro = () => {
  const gestionar = { 
    nombre: 'integracion', 
    id_tienda: tienda,
    pagina: currentPage,
    searchText: searchText
  };
  localStorage.setItem('gestionar', JSON.stringify(gestionar));
};
const selectTiendaOnchange = (e) => {
    try {
      setTienda(e);
      //updateFiltro();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
};

const handleExport = async() => {
  try{
    if (tienda=="") {
      construirMensaje('Seleccione una tienda para poder consultar','Falta parametros','info');
    }else{
        setIsLoading(true);
        let $url=`integracion/export`;
        const formData = new FormData();
        formData.append('tienda', tienda);
        const token = localStorage.getItem('token_baken');
            const response = await fetch(urlcontext + $url, {
              method: 'POST',
              body: formData,
              accept:"application/json",
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });
            const blob = await response.blob();
            // Crear un objeto URL a partir del blob
            const url = window.URL.createObjectURL(blob);
            // Crear un enlace temporal y simular un clic para descargar el archivo
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Homologaciones.xlsx');
            document.body.appendChild(link);
            link.click();
            // Liberar el objeto URL y eliminar el enlace temporal
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setIsLoading(false);
    }
  } catch (error) {
    setIsLoading(false);
    construirMensaje(error.message,'Error durante el proceso','error');
  }
};

const handleDownloadTemplete = async() => {
  try{
      setIsLoading(true);
      let $url=`integracion/templete`;
      const formData = new FormData();
      formData.append('tienda', tienda);
      const token = localStorage.getItem('token_baken');
          const response = await fetch(urlcontext + $url, {
            accept:"application/json",
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          const blob = await response.blob();
          // Crear un objeto URL a partir del blob
          const url = window.URL.createObjectURL(blob);
          // Crear un enlace temporal y simular un clic para descargar el archivo
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'homologacionesTemplete.xlsx');
          document.body.appendChild(link);
          link.click();
          // Liberar el objeto URL y eliminar el enlace temporal
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          construirMensaje(error.message,'Error durante el proceso','error');
        }
};


  const handlePageChange = (pageNumber) => {
    let primerItem = 1;
    setCurrentPage(pageNumber);
    primerItem=(pageNumber * perpage) - perpage + 1;
    setCountitem(primerItem);
 
    
  };

  const handleNextPage = () => {
    let primerItem = 1;
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
      primerItem=((currentPage + 1) * perpage) - perpage + 1;
      setCountitem(primerItem);
    }
   
  };

  const handlePreviousPage = () => {
    let primerItem = 1;
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      primerItem=((currentPage - 1) * perpage) - perpage + 1;
      setCountitem(primerItem);
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];

    // Previous button
    buttons.push(
      <li className="page-item" key='liprevious'>
        <button key="previous" className="page-link" disabled={currentPage === 1} onClick={handlePreviousPage}>
          Previous
        </button>
      </li>
    );

    // Page buttons
    for (let i = 1; i <= lastPage; i++) {
      buttons.push(
        <li className="page-item" key={'li-'+ i}>
        <button key={i} className={`page-link ${currentPage == i ? 'active' : ''}`} onClick={() => handlePageChange(i)}>
          {i}
        </button>
        </li>
      );
    }

    // Next button
    buttons.push(
      <li className="page-item" key='linext'>
      <button
        key="next"
        className="page-link"
        disabled={currentPage === lastPage}
        onClick={handleNextPage}
      >
        Next
      </button>
      </li>
    );
    return buttons;
  };
  return (
<>
{isLoading ? (
        <SpinnerComponent />
      ):(null)}
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"> 
            <h2 className="h2">Homologacion SIC - Shopify </h2>
    </div>
    <Container fluid className="row pt-3 mb-3">
    <Row>
    <Col>
        <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            <BiCategory size="24px"/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={()=>handleShow()}><BiFolderPlus size="24px"/> Cargar homologacion</Dropdown.Item>
              <Dropdown.Item onClick={()=>handleExport()} ><BiExport size="24px"/> Exportar homologacion</Dropdown.Item>
              <Dropdown.Item onClick={()=>handleDownloadTemplete()}><BiExport size="24px"/> Descargar plantilla xlsx para montar homologaciones </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col>
              <Form.Group className="mb-3">
                <Form.Select
                  aria-label="Default select example"
                  id="selectOption"
                  name='tienda'
                  value={tienda}
                  onChange={(e) => selectTiendaOnchange(e.target.value)}
                >
                  <option value="">Seleccione una tienda</option>
                  {opcionesSelect.map((opcion) => (
                    <option key={opcion.id} value={opcion.id}>
                      {opcion.nombre}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
          </Col>
          <Col>
            <Form className="d-flex" onSubmit={handleSearch}>
              <FormControl type="search" name="search" placeholder="Buscar por: id, usuario, estado, observacion" className="me-2" aria-label="Buscar" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
              <Button variant="outline-primary" type="submit"><BiSearch /></Button>
            </Form>
          </Col>
          </Row>
        </Container>
    <div className="container-fluid mb-3"> 
    <div className="row">
    <div className="table-responsive">
      <Table striped bordered hover className="custom-table table" >
        <thead className='table-header'>
          <tr>
            <th>#</th>
            <th>Id</th>
            <th>Tienda</th>
            <th>Usuario</th>
            <th>Estado</th>
            <th>Fecha</th>
            <th>Observacion</th>
            <th>Detalles</th>
          </tr>
        </thead>
        <tbody>
        {
        integrations.map((integration, index) => (
            <tr key={integration.id}>
              <td>
                {countitem + index}
              </td>
              <td>{integration.id}</td>
              <td>{integration.store.nombre}</td>
              <td>{integration.user.name}</td>
              <td>
                {
              integration.status==='complete' ? (
                <Badge pill bg="success">
                    {integration.status}
                  </Badge>
                  ):(<Badge pill bg="warning" text="dark">
                  {integration.status}
                </Badge>
                )
              }
              </td>
              <td>{moment(integration.created_at).locale('es').format('DD-MM-YYYY HH:mm:ss')}</td>
              <td>{integration.observation}
              </td>
              <td> <Link to={`/gestionarhomologacion/listarhomologacion/${integration.id}`}>
                    <svg className="bi me-2" width="16" height="16"></svg>
                    Ver detalles
                  </Link></td>
            </tr>
            ))
            }
        </tbody>
      </Table>
      </div>
        <div className="d-flex justify-content-center">
        <nav aria-label="Page navigation example"> 
          <ul className="pagination">
            {renderPaginationButtons()}
          </ul>
        </nav>
      </div>
      </div>
     </div>
      <RegistrarIntegracion show={show} handleClose={handleClose}/>     
</>
  );
}

export default GestionarIntegracion;