// apiHelper.js
import Swal from "sweetalert2";
import { redirect } from "react-router-dom";

// Helper para realizar una solicitud GET a la API
export const preurl = "https://backendgbl.continente.co/api/";
//export const preurl = "http://localhost:8000/api/";
//export const preurl = 'http://localhost:8000/api/';
export const get = async (url) => {
  let data = "";
  try {
    const token = localStorage.getItem("token_baken");
    //console.log(token);
    const response = await fetch(preurl + url, {
      accept: "application/json",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      if (response.status == 400 || response.status == 500) {
        throw new Error("Failed to fetch");
      } else {
        const errorMessage = await response.text();
        throw new Error(
          `Error al obtener los datos. Código de estado: ${response.status}, Mensaje: ${errorMessage}`
        );
      }
    } else {
      data = await response.json();
    }
    // data = await response.json();
    return data;
  } catch (error) {
    if (error.message === "Failed to fetch") {
      Swal.fire({
        icon: "error",
        title: "Sesion terminada",
        text: "Inicie sesion nuevamente",
        footer: "",
        showCancelButton: false,
        confirmButtonText: "OK",
      }).then((result) => {
        localStorage.removeItem("token_baken");
        localStorage.removeItem("gestionar");
        localStorage.removeItem("usuario_actual");
        localStorage.removeItem("detallefiltro");
        window.location.reload();
      });
    } else console.error("Error al realizar la solicitud GET:", error);
  }
};
// Helper para realizar una solicitud POST a la API
export const post = async (url, dataform) => {
  try {
    const token = localStorage.getItem("token_baken");
    const response = await fetch(preurl + url, {
      method: "POST",
      body: dataform,
      accept: "application/json",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Error al obtener los datos");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    if (error.message === "Failed to fetch") {
      Swal.fire({
        icon: "error",
        title: "Sesion terminada",
        text: "Intente iniciando sesion nuevamente haciendo click en Sign out",
        footer: "",
        showCancelButton: false,
        confirmButtonText: "OK",
      }).then((result) => {
        localStorage.removeItem("token_baken");
        localStorage.removeItem("gestionar");
        localStorage.removeItem("usuario_actual");
        localStorage.removeItem("detallefiltro");
        return "401";
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Error durante el proceso",
        html: error.message,
        footer: "",
      });
    }
  }
};

// Helper para realizar una solicitud PUT a la API
export const put = async (url, dataform) => {
  try {
    const token = localStorage.getItem("token_baken");
    const response = await fetch(preurl + url, {
      method: "PUT",
      body: dataform,
      accept: "application/json",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    if (error.message === "Failed to fetch") {
      Swal.fire({
        icon: "error",
        title: "Sesion terminada",
        text: "Intente iniciando sesion nuevamente haciendo click en Sign out",
        footer: "",
        showCancelButton: false,
        confirmButtonText: "OK",
      }).then((result) => {
        localStorage.removeItem("token_baken");
        localStorage.removeItem("gestionar");
        localStorage.removeItem("usuario_actual");
        localStorage.removeItem("detallefiltro");
        return "401";
      });
    } else console.error("Error al realizar la solicitud GET:", error);
  }
};

export const remove = async (url) => {
  try {
    const token = localStorage.getItem("token_baken");
    const response = await fetch(preurl + url, {
      method: "DELETE",
      accept: "application/json",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    if (error.message === "Failed to fetch") {
      Swal.fire({
        icon: "error",
        title: "Sesion terminada",
        text: "Intente iniciando sesion nuevamente haciendo click en Sign out",
        footer: "",
        showCancelButton: false,
        confirmButtonText: "OK",
      }).then((result) => {
        localStorage.removeItem("token_baken");
        localStorage.removeItem("gestionar");
        localStorage.removeItem("usuario_actual");
        localStorage.removeItem("detallefiltro");
        return "401";
      });
    } else console.error("Error al realizar la solicitud GET:", error);
  }
};

export const construirMensaje = (data, titulo, icono) => {
  let isjoson = false;
  try {
    const jsonObject = JSON.parse(data);
    isjoson = true;
  } catch (error) {}
  if (isjoson) {
    const jsonObject = JSON.parse(data);
    let errores = "<ul>";
    for (const campo in jsonObject) {
      if (jsonObject.hasOwnProperty(campo)) {
        errores += `<li key=${campo}> ${jsonObject[campo][0]}</li>`;
      }
    }
    errores += "</ul>";
    Swal.fire({
      icon: icono,
      title: titulo,
      html: errores,
    });
  } else {
    Swal.fire({
      icon: icono,
      title: titulo,
      html: data,
    });
  }
};
