import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Table, Form,Col,Row,Dropdown,FormControl,Button} from 'react-bootstrap';
import {BiMenu,BiSearch} from 'react-icons/bi';



function ListPrecios(props) {
  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState([]);
  const token = localStorage.getItem('token');
  return (
<>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"> 
            <h3>Lista de Precios a Cargar</h3>
    </div>
    <div className="container-fluid mb-3">
    <div className="row border rounded-1">
          <div className="col-4">
            <Form>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                  <Form.Label column sm="3" className='text-end'>
                    Tienda:
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control plaintext readOnly defaultValue="Lego Colombia" className='fst-italic' />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                  <Form.Label column sm="3" className='text-end'>
                    # de Cargar:
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control plaintext readOnly defaultValue="545454" className='fst-italic' />
                  </Col>
                </Form.Group>
            </Form>
          </div>
          <div className="col-4">
            <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" className='text-end'>
                      Archivo Cargado:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control plaintext readOnly defaultValue="rueba.csv" className='fst-italic'/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" className='text-end'>
                      Fecha de Proceso:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control plaintext readOnly defaultValue="Marzo,10 de 2023 8:00am" className='fst-italic' />
                    </Col>
                </Form.Group>
            </Form>
          </div>
          <div className="col-4">
            <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" className='text-end'>
                      Total Precios Cargados:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control plaintext readOnly defaultValue="101" className='fst-italic' />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" className='text-end'>
                      Precios con error:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control plaintext readOnly defaultValue="99"  className='fst-italic'/>
                    </Col>
                </Form.Group>
            </Form>
          </div>
          <div className="col-4">
            <Form>
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                    <Form.Label column sm="3" className='text-end'>
                      # Actualizados en Tienda:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control plaintext readOnly defaultValue="101" className='fst-italic' />
                    </Col>
                </Form.Group>
            </Form>
          </div>
    </div>
    </div>
    <div className="container-fluid mb-3">
      <div className="row">
          <div className="col-8">
          {/* <Button variant="outline-primary" onClick={handleShow}><BiFolderPlus size="20px"/> Cargar Articulos</Button>{' '} */}
          </div>
            <div className="col-4">
              <Form className="d-flex">
                <FormControl type="search" placeholder="Buscar" className="me-2" aria-label="Buscar" />
                <Button variant="outline-primary" type="submit"><BiSearch /></Button>
              </Form>
            </div>
      </div>
    </div>
    <div className="container-fluid mb-3">
      <div className="row border rounded-1">
      <Table responsive striped bordered hover className="table mt-5">
        <thead className="table-header">
          <tr>
            <th>Codigo SIC</th>
            <th>Titulo articulo</th>
            <th>Nombre articulo</th>
            <th>Precio normal</th>
            <th>Precio en oferta</th>
            <th>Estado ecommerce</th>
            <th>Errores</th>
            <th>opciones</th>
          </tr>
        </thead>
        <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <BiMenu/>
                </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Editar</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Actualizar en tienda</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Eliminar</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <BiMenu/>
                </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Editar</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Actualizar en tienda</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Eliminar</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <BiMenu/>
                </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Editar</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Actualizar en tienda</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Eliminar</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
        </tbody>
      </Table>
      <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item"><a className="page-link" href="#">Previous</a></li>
            <li className="page-item"><a className="page-link" href="#">1</a></li>
            <li className="page-item"><a className="page-link" href="#">2</a></li>
            <li className="page-item"><a className="page-link" href="#">3</a></li>
            <li className="page-item"><a className="page-link" href="#">Next</a></li>
          </ul>
        </nav>
      </div>
    </div>
</>
  );
}

export default ListPrecios;