import React, { useState, useEffect, useRef } from 'react';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { Modal, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import SpinnerComponent from '../layout/spinner';
import { useNavigate } from 'react-router-dom';
import { post,get,construirMensaje }  from "../../helpers/apiHttp";

const RegistrarIntegracion = ({ show, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [opcionesSelect, setOpcionesSelect] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const token = localStorage.getItem('token');
  const history = useNavigate();
  const [formulario, setFormulario] = useState({
    tienda: "",
    descuentosxlsx: '',
    observacion: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormulario((prevFormulario) => ({
      ...prevFormulario,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('tienda', formulario.tienda);
    formData.append('observacion', formulario.observacion);
    try {
      if (!isSubmitting) {
        let $url='integracion';
        setIsLoading(true);
        const data = await post($url,formData);
        setIsLoading(false);
        if (data.isSuccessful==true) {
          if (data.statusCode==210) {
            construirMensaje(data.errorMessage,'Proceso exitoso','info');
          }else{
            construirMensaje("Las homologaciones se registraron correctamente",'Proceso exitoso','success');
          }
          history(`/gestionarhomologacion/listarhomologacion/${data.result}`);
        }else{
          construirMensaje(data.errorMessage,'Error durante el proceso','error');
        }
      }
    } catch (error) {
      construirMensaje("Error al enviar el formulario",'Error de formulario','error');
    }
  };

  useEffect(() => {
    if (show) {
      fetchOpcionesSelect();
    }
  }, [show]);

  const fetchOpcionesSelect = async () => {
    try {
       let $url='tiendas';
        setIsLoading(true);
        const data = await get($url);
        setIsLoading(false); 
      setOpcionesSelect(data.result);
    } catch (error) {
      construirMensaje("Error al enviar el formulario",'Error de formulario',error);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  return (
    <>
    {isLoading ? (
        <SpinnerComponent />
      ) : (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cargar Homologaciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Tienda</Form.Label>
              <Form.Select
                aria-label="Default select example"
                id="selectOption"
                name='tienda'
                value={formulario.tienda}
                onChange={handleInputChange}
              >
                <option value="">Seleccione una opción</option>
                {opcionesSelect.map((opcion) => (
                  <option key={opcion.id} value={opcion.id}>
                    {opcion.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Adjuntar Archivo XLSX</Form.Label>
              <Form.Control
                type="file"
                onChange={handleFileChange}
                accept=".xlsx"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>Observacion</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name='observacion'
                value={formulario.observacion}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting} onClick={handleSubmit}>
            Cargar Homologaciones
          </Button>
        </Modal.Footer>
      </Modal>
      )}
    </>
  );
}


export default RegistrarIntegracion;