import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { Table, Form, Button, FormControl } from 'react-bootstrap';
import { BiSearch,BiFolderPlus} from 'react-icons/bi';
import { BiShow } from 'react-icons/bi';
import CargarPrecios from './CargarPrecios';


function GestionarPrecios(props) {
  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState([]);
  const token = localStorage.getItem('token');
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
<>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"> 
          <h2 className="h2">Gestionar Precios</h2>
    </div>
    <div className="container-fluid">
    <div className="row">
        <div className="col-8">
        <Button variant="outline-primary" onClick={handleShow}><BiFolderPlus size="20px"/> Actualizar Precios</Button>{' '}
        </div>
          <div className="col-4">
            <Form className="d-flex">
              <FormControl type="search" placeholder="Buscar" className="me-2" aria-label="Buscar" />
              <Button variant="outline-primary" type="submit"><BiSearch /></Button>
            </Form>
          </div>
    </div>
    </div>
      <Table responsive striped bordered hover className="table mt-5">
        <thead className='table-header'>
          <tr>
            <th>Id</th>
            <th>Usuario</th>
            <th>Tienda</th>
            <th>Fecha de proceso</th>
            <th>CSV</th>
            <th># Descuentos creados</th>
            <th># Descuentos con errores</th>
            <th>Observacion</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>5</td>
              <td>
                  <Link to="/gestionarprecios/listarprecios">
                    <svg className="bi me-2" width="16" height="16"></svg>
                    Ver actualizacion
                  </Link>
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>5</td>
              <td>
                  <Link to="/gestionarprecios/listarprecios">
                    <svg className="bi me-2" width="16" height="16"></svg>
                    Ver actualizacion
                  </Link>
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>5</td>
              <td>
                  <Link to="/gestionarprecios/listarprecios">
                    <svg className="bi me-2" width="16" height="16"></svg>
                    Ver actualizacion
                  </Link>
              </td>
            </tr>
        </tbody>
      </Table>
      <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item"><a className="page-link" href="#">Previous</a></li>
            <li className="page-item"><a className="page-link" href="#">1</a></li>
            <li className="page-item"><a className="page-link" href="#">2</a></li>
            <li className="page-item"><a className="page-link" href="#">3</a></li>
            <li className="page-item"><a className="page-link" href="#">Next</a></li>
          </ul>
        </nav>
      <CargarPrecios show={show} handleClose={handleClose}/>
</>
  );
}

export default GestionarPrecios;