import React, { useState,useContext,useEffect } from 'react';
import Swal from 'sweetalert2';
import { NavDropdown, Navbar, Button, Nav, Container, ControlLabel } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { get, post, put, remove }  from "../../helpers/apiHttp";
import SpinnerComponent from '../layout/spinner';
import { useNavigate } from 'react-router-dom';
import  UpdatePassword  from '../login/UpdatePassword';

function Header(props) {
  const { setIsLoggedIn } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [nombreusuario, setNombreusuario] = useState("");
  const [showModal, setShowModal] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    setNombreusuario(localStorage.getItem('usuario_actual'));
  }, []);
  const handleLogout = async () =>{
    setIsLoading(true);
    let $url=`logout`;
    try {
    const data = await post($url);
      setIsLoading(false);
       if (data.isSuccessful==true && data.statusCode =='204') {
        localStorage.removeItem('token_baken');
        localStorage.removeItem('gestionar');
        localStorage.removeItem('usuario_actual');
        setIsLoggedIn(false);
        history("/");
      } 
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      localStorage.removeItem('token_baken');
      localStorage.removeItem('gestionar');
      localStorage.removeItem('usuario_actual');
      setIsLoggedIn(false);
      history("/");
      // Realiza el manejo de error adecuado
    }
   };
   const cerrarModal = () => {
    setShowModal(false);
  };
  const show_cahgepassword = (detail_id) => { 
      setShowModal(true); 
  };
    const capitalizeWords = (str) => {
     return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
   };

  return (
<>
<header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
<a className="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">Continente SAS.</a>
  <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="ms-auto" style={{ color: 'white'}}>Usuario actual:</div>
{/*   <div className="navbar-nav">
    <div className="nav-item text-nowrap">
    <Button variant="link" className="nav-link px-3" onClick={handleLogout}>
      Cerrar sesión
    </Button>
    </div>
  </div> */}
  <Navbar variant="dark" bg="dark" expand="lg">
      <Container fluid>
        {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="navbar-dark-example" />
        <Navbar.Collapse id="navbar-dark-example">
          <Nav>
            <NavDropdown
              id="nav-dropdown-dark-example"
              title={nombreusuario}
              menuVariant="dark"
              align={{ lg: 'end' }}
            >
              <NavDropdown.Item onClick={show_cahgepassword}>Cambiar Contraseña</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogout}>
              Cerrar sesión
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    
</header>
<UpdatePassword show={showModal} handleClose={cerrarModal}/>
</>
  );
}

export default Header;