import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const equivaleciadir ={
    gestionararticulos:'Gestionar articulos',
    listarticulos:'Listar articulos',
    gestionardescuentos:'Gestionar descuentos',
    listardescuentos:'Listar descuentos',
    gestionarprecios:'Gestionar precios',
    listarprecios:'Listar precios',
    gestionarhomologacion:'Gestionar homologaciones',
    listarhomologacion:'Detalles homologacion',
    home:'Home',
    };
  return (
    <nav aria-label="breadcrumb" className='border-bottom d-flex align-items-center mb-4'>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          if (!typeof name !== 'number') {
            return (
              <li key={name} className={`breadcrumb-item ${isLast ? 'active' : ''}`} aria-current={isLast ? 'page' : null}>
                {isLast ? (
                  name
                ) : (
                  <Link to={routeTo}>{equivaleciadir[name]}</Link>
                )}
              </li>
            );
          } 
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
