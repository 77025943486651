import React, { useState, useEffect, useRef } from 'react';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { Modal, Form, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import SpinnerComponent from '../layout/spinner';
import { useNavigate } from 'react-router-dom';
import { post,get,construirMensaje, put  }  from "../../helpers/apiHttp";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const RegistrarIdentificacion = ({ show, handleClose, order , tienda, busqueda }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [opcionesSelect, setOpcionesSelect] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const token = localStorage.getItem('token');
  const history = useNavigate();
  const [formulario, setFormulario] = useState({
    tipoIdentificacion: "",
    identificacion: ''
  });

  const resetFormulario = () => {
    setFormulario({
        tipoIdentificacion: "",
      identificacion: "",
      tienda: tienda,
      idorden: order.idshopify,
    });
  };

  //se ejecuta al abrir el formulario
  useEffect(() => {
    if (show) {
      resetFormulario();
      fetchOpcionesSelect(tienda);
    }
  }, [show]);

  const fetchOpcionesSelect = async (tienda_id) => {
    const formData = new FormData();
    formData.append('tienda', tienda_id);
    try {
      let $url = 'backend/identicationTypes'; // Reemplaza con tu endpoint real
      setIsLoading(true);
      const data = await post($url,formData);
      setIsLoading(false);
      if (data.statusCode==200) {
         setOpcionesSelect(data.result); // Guardar las opciones del endpoint
      } else {
        Swal.fire('Error', 'No se pudieron cargar las opciones', 'error');
      }
    } catch (error) {
      console.error(error);
      Swal.fire('Error', 'Error al cargar las opciones', 'error');
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormulario((prevFormulario) => ({
      ...prevFormulario,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    console.log(formulario.tienda);
    formData.append('tipoidentificacion', formulario.tipoIdentificacion);
    formData.append('identificacion', formulario.identificacion);
    formData.append('tienda', formulario.tienda);
    formData.append('idorden', formulario.idorden);
    try {
      if (!isSubmitting) {
        let $url='backend/update/identificacionOrden';
        setIsLoading(true);
        const data = await post($url,formData);
        setIsLoading(false);
        if (data.isSuccessful==true && data.statusCode==200) {
            construirMensaje(data.errorMessage,'La identificacion fue registrada con exito','success'); 
            busqueda();
        }else{
          construirMensaje(data.errorMessage,'Error durante el proceso','error');
        }
      }
      //construirMensaje(data.errorMessage,'Error durante el proceso','error');
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error de formulario',
        text: "Error al enviar el formulario",
        footer: ''
      });
    }
    handleClose();
  };
  /*const fetchOpcionesSelect = async () => {
    try {
       let $url='tiendas';
        setIsLoading(true);
        const data = await get($url);
        setIsLoading(false); 

      setOpcionesSelect(data.result);
    } catch (error) {
      console.error(error);
    }
  };*/

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  return (
    <>
    {isLoading ? (
        <SpinnerComponent />
      ) : (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Registrar Identificación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Form.Group className="mb-3">
              <Form.Label>Tipo Identificacion</Form.Label>
              <Form.Select
                  aria-label="Seleccione un tipo de identificación"
                  name="tipoIdentificacion"
                  value={formulario.tipoIdentificacion}
                  onChange={handleInputChange}
                >
                  <option value="">Seleccione una opción</option>
                  {opcionesSelect.map((opcion) => (
                    <option key={opcion.id} value={opcion.code}>
                      {opcion.nombre}
                    </option>
                  ))}
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label>Identificación</Form.Label>
              <Form.Control
                type="numeric"
                name='identificacion'
                value={formulario.identificacion}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={isSubmitting} onClick={handleSubmit}>
            Registrar Identificacion
          </Button>
        </Modal.Footer>
      </Modal>
      )}
    </>
  );
}
export default RegistrarIdentificacion;