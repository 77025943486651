import Spinner from 'react-bootstrap/Spinner';

function BasicExample() {
  return (
    <div className="spinner-overlay">
        <div className="spinner-container">
            <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    </div>
  );
}

export default BasicExample;
